import React, { useEffect, useState } from "react";
import { useWindowSize } from "../../utils/useWindowSize";
import { SeperatorComponentProps } from "./SeperatorComponent.types";

const SeperatorComponent: React.FC<SeperatorComponentProps> = ({
  height = 60,
  browserOnly,
  mobileOnly,
}) => {
  const { width } = useWindowSize();
  const [showElement, setShowElement] = useState<boolean>(true);

  /**
   * this useeffects updates the visibility of the div
   */
  useEffect(() => {
    switch (true) {
      case width < 600 && mobileOnly:
      case width > 600 && browserOnly:
        setShowElement(true);
        break;
      case width > 600 && mobileOnly:
      case width < 600 && browserOnly:
        setShowElement(false);
        break;
      default:
        setShowElement(true);
        break;
    }
  }, [width]);

  return (
    <div
      style={{
        height: `${height}px`,
        display: showElement ? undefined : "none",
      }}
    />
  );
};

export default SeperatorComponent;
