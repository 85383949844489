import React from "react";
import "./InputComponent.styles.scss";
import { InputComponentProps } from "./InputComponent.types";

const InputComponent: React.FC<InputComponentProps> = ({
  value,
  id,
  name,
  onChange,
  placeholder,
  type = "text",
  theme = "default",
  label,
  required = false,
}) => {
  const localLabel: string = `${label}${required ? " *" : ""}`;

  return (
    <div className="input-component">
      {!!label && <div className="label-wrapper">{localLabel}</div>}
      {type === "multiline" ? (
        <textarea
          value={value}
          id={id}
          required={required}
          name={name}
          placeholder={placeholder}
          onChange={(evt) => onChange?.(evt.target.value)}
          readOnly={!onChange}
          className={[
            "input-component--input",
            theme === "bordered" ? "bordered" : undefined,
          ].join(" ")}
        />
      ) : (
        <input
          className={[
            "input-component--input",
            theme === "bordered" ? "bordered" : undefined,
          ].join(" ")}
          type={type}
          required={required}
          readOnly={!onChange}
          value={value}
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={(evt) => onChange?.(evt.target.value)}
        />
      )}
    </div>
  );
};

export default InputComponent;
