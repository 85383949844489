import React, { useEffect, useState } from "react";
import { graphql, StaticQueryDocument } from "gatsby";
import "../styles/general.styles.scss";
import "../styles/contact.styles.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import LayoutComponent from "../components/LayoutComponent/LayoutComponent";
import { CircleConfig } from "../components/ContentRowComponent/ContentRowComponent.types";
import { useWindowSize } from "../utils/useWindowSize";
import ContentRowComponent from "../components/ContentRowComponent/ContentRowComponent";
import SeperatorComponent from "../components/SeperatorComponent/SeperatorComponent";
import InputComponent from "../components/InputComponent/InputComponent";
import ButtonComponent from "../components/ButtonComponent/ButtonComponent";

const ContactPage: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [separatorHeight, setSeparatorHeight] = useState<number>(600);
  const [message, setMessage] = useState<{
    salut: string;
    name: string;
    mail: string;
    phone: string;
    message: string;
  }>({ mail: "", name: "", phone: "", salut: "", message: "" });
  const [circleConfigs, setCircleConfigs] = useState<CircleConfig[]>([
    {
      color: "#f89a26",
      radius: 2000,
      weight: 8,
      active: true,
      horiz: -500,
    },
  ]);

  /**
   *
   */
  useEffect(() => {
    switch (true) {
      case width > 769:
        setCircleConfigs([
          {
            color: "#f89a26",
            radius: 2000,
            weight: 8,
            active: true,
            horiz: -500,
          },
        ]);
        setSeparatorHeight(600);
        break;

      case width > 600 && width <= 769:
        setCircleConfigs([
          {
            color: "#f89a26",
            radius: 2000,
            weight: 8,
            active: true,
            horiz: -500,
            top: -300,
          },
        ]);
        setSeparatorHeight(150);
        break;

      case width <= 600:
      default:
        setCircleConfigs([
          {
            color: "#f89a26",
            radius: 1000,
            weight: 8,
            active: true,
            horiz: -300,
            top: -280,
          },
        ]);
        setSeparatorHeight(100);
        break;
    }
  }, [width]);

  return (
    <LayoutComponent seo={{ title: t("page.contact.title") }} sub>
      <ContentRowComponent className="column-wrapper">
        <div className="contact-sub-page">
          <div className="headline-h2">Kontakt</div>
          <div className="text">
            Hast du Fragen Lorem ipsum Oxmoxo Lorem ipsum OxmoxoLorem ipsum
            OxmoxoLorem ipsum OxmoxoLorem ipsum Oxmoxo
          </div>

          <div className="flex-row-wrapper">
            <InputComponent
              required
              label="Anrede"
              value={message.salut}
              onChange={(value) => setMessage({ ...message, salut: value })}
              theme="bordered"
            />
            <InputComponent
              required
              label="Name"
              value={message.name}
              onChange={(value) => setMessage({ ...message, name: value })}
              theme="bordered"
            />
          </div>
          <div className="flex-row-wrapper">
            <InputComponent
              label="Deine E-Mail-Adresse"
              required
              value={message.mail}
              type="email"
              onChange={(value) => setMessage({ ...message, mail: value })}
              theme="bordered"
            />
            <InputComponent
              label="Deine Telefonnummer"
              value={message.phone}
              onChange={(value) => setMessage({ ...message, phone: value })}
              theme="bordered"
            />
          </div>
          <InputComponent
            required
            label="Deine Nachricht an uns"
            type="multiline"
            theme="bordered"
            value={message.message}
            onChange={(value) => setMessage({ ...message, message: value })}
          />
          <ButtonComponent title="Absenden" invers />
        </div>
      </ContentRowComponent>
      <ContentRowComponent
        circleConfig={circleConfigs[0]}
        className="minimal-z-index"
      >
        <SeperatorComponent height={separatorHeight} />
      </ContentRowComponent>
    </LayoutComponent>
  );
};

export default ContactPage;

export const query: StaticQueryDocument = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    medicineParallaxImage: file(relativePath: { eq: "9010-medizin.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeParallaxImage: file(relativePath: { eq: "9010-zuhause.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jobParallaxImage: file(relativePath: { eq: "9010-beruf.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sportParallaxImage: file(relativePath: { eq: "9010-sport.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
