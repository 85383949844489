import React, { useRef } from "react";
import "./ButtonComponent.styles.scss";
import { ButtonComponentProps } from "./ButtonComponent.types";

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  invers = false,
  title,
  theme = "action",
  bgColor,
  type = "button",
  id,
  name,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  /**
   *
   * @returns
   */
  const generateCorrectCssClasses = (): string => {
    let cssClasses: string[] = ["button-wrapper"];
    switch (theme) {
      case "action":
        cssClasses.push("action");
        break;
      case "category":
        cssClasses.push("category");
        break;
      case "intro":
        cssClasses.push("intro");
        break;
      case "outline":
        cssClasses.push("outline");
        break;
    }
    if (invers) cssClasses.push("white");
    return cssClasses.join(" ");
  };

  return (
    <div className="button-component">
      <button
        name={name}
        id={id}
        ref={buttonRef}
        onMouseEnter={() => {
          if (!buttonRef.current) return;
          buttonRef.current.style.color = bgColor ?? "#f89a26";
        }}
        onMouseLeave={() => {
          if (!buttonRef.current) return;
          buttonRef.current.style.color = invers ? "white" : "black";
        }}
        type={type}
        className={generateCorrectCssClasses()}
        style={bgColor ? { background: bgColor, borderColor: bgColor } : {}}
      >
        {title}
      </button>
    </div>
  );
};

export default ButtonComponent;
